html,body {
  background: #F2F6FA;
}
footer {
  background-color: #F2F6FA !important;
}
.topNav {
  border-top: 5px solid #3498DB;
}
.topNav .container {
  border-bottom: 1px solid #E6EAEE;
}
.container .columns {
  margin: 3rem 0;
}
.navbar-menu .navbar-item {
  padding: 0 2rem;
}
aside.menu {
  padding-top: 3rem;
}
aside.menu .menu-list {
  line-height: 1.5;
}
aside.menu .menu-label {
  padding-left: 10px;
  font-weight: 700;
}
.button.is-primary.is-alt {
  background: #00c6ff;
  background: -webkit-linear-gradient(to bottom, #0072ff, #00c6ff);
  background: linear-gradient(to bottom, #0072ff, #00c6ff);
  font-weight: 700;
  font-size: 14px;
  height: 3rem;
  line-height: 2.8;
}
.media-left img {
  border-radius: 50%;
}
.media-content p {
  font-size: 14px;
  line-height: 2.3;
  font-weight: 700;
  color: #8F99A3;
}
article.post {
  margin: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #E6EAEE;
}
article.post:last-child {
  padding-bottom: 0;
  border-bottom: none;
}
.menu-list li{
  padding: 5px;
}

.image.logo {
  height: 300px;
  width: 300px;
}

.red {
  background-color: red;
}

.heart {
  /* background-color: red; */
  color: red;
}

/* Overwrite the default border-radios:50% on media-left images */
.media-left img {
  border-radius: 5%;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .image.logo {
    height: 240px;
    width: 240px;
  }
}

.card-product:hover {
  box-shadow: 0 8px 13px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}

.border-bottom {
  border-bottom: 1px solid gray;
}

.image-product {
  height: 128px;
  width: 128px;
}

.inline{
  /* justify-content: center; */
  display: inline !important;
}